import React from 'react';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.appHeader}>
      Process UI Here
    </div>
  );
}

export default App;
